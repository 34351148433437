
.divider {
  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;
  height: 1px;
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(48, 49, 51),
    transparent
  );
}
.div-dot:after {
  background: url(~/public/comb.svg) no-repeat;
  background-size: 32px 32px;
  background-position: center;
  content: "";
  position: absolute;
  z-index: 1;
  top: -20px;
  background-color: white;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}
